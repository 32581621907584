// --------------------------------------------------------
// Convenience Classes
// --------------------------------------------------------
.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.helper-text {
  color: darken($helper-text-color, 10%);
  text-transform: lowercase;
}

.reset-spacing {
  margin: 0px;
  padding: 0px;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing-unit;
  padding-right: $spacing-unit;
  @extend %clearfix;
  
  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    
  }
}

// --------------------------------------------------------
// Basic Elements
// --------------------------------------------------------

body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0px;
  padding: 0px;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: $base-font-family;
  font-size: $type-size-normal;
  line-height: $base-line-height;
  color: $base-text-color;
  font-weight: 300;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
}

//Set `margin-bottom` to maintain vertical rhythm
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 3;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  font-weight: $heading-font-weight;
  color: $heading-text-color;
  margin-top: $spacing-unit * 0.1;
  margin-bottom: $spacing-unit * 0.1;
  display: block;
}

h1 {
  margin-top: $type-size-3 * 0.2;
  margin-bottom: $type-size-3 * 0.2;
  font-family: $sans-serif;
  font-size: $type-size-large;
  font-weight: 600;
}

h2 {
  font-size: $type-size-large;
  margin-top: $type-size-4 * 0.1;
  margin-bottom: $type-size-4 * 0.1;
}

h3 {
  font-size: 0.9 * $type-size-large;
  font-weight: 600;
  color: $base-text-color;
  margin-top: $type-size-4 * 0.5;
  margin-bottom: $type-size-4 * 0.1;
}


h4 {
  font-size: 0.9 * $type-size-large;
  font-weight: 600;
  margin-top: $type-size-4 * 0.2;
  margin-bottom: $type-size-4 * 0.2;
}

hr {
  margin-top: $rule-margin;
  margin-bottom: $rule-margin;
}

a {
  color: $link-color;
  text-decoration: none;

  &:visited {
    color: $link-color-visited;
  }

  &:hover {
    color: $link-color-hover;
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
  vertical-align: middle;
}

figure > img {
  display: block;
}

figcaption {
  font-size: $type-size-small;
}

ul, ol {
  margin-left: 2 * $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

blockquote {
  color: $gray;
  border-left: 4px solid $light-gray;
  padding-left: $spacing-unit / 2;
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

pre, code {
  font-size: 14px;
  border: 1px solid $light-gray;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: scroll;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

// Images
.align-left {
  float: left;
  margin-top: 4px;
  margin-right: 20px;
  margin-bottom: 15px;
}

.align-right {
  float: right;
  margin-top: 4px;
  margin-left: 20px;
  margin-bottom: 15px;
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.bio-photo {
  float: left;
  margin-bottom: 0 * $spacing-unit;
  margin-top: 4px;
  margin-right: 20px;
  margin-bottom: 0.5 * $spacing-unit;
  @include media-query($on-palm) {
    background-color: inherit;
    display: block;
    margin-top: $spacing-unit;
    margin-left:  auto;
    margin-right: auto;
    width: 50%;
  }
}

.about-me-text {
  clear: both;
  p {
    padding-bottom: 1.0 * $spacing-unit;
  }
}


.contact-info {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  padding-top: 1.0 * $spacing-unit;
  @include media-query($on-palm) {
    clear: both;
    padding-top: 1.5 * $spacing-unit;
    padding-left: 1.0 * $spacing-unit;
  }
  
}

.contact-list {
  padding: 0px;
  margin: 0px;
  padding-top: 1.0 * $spacing-unit;
  display: block;
  list-style-type: none;
  overflow: hidden;
}

.contact-list-item {
  display: block;
  text-align: left;
  padding-left: $spacing-unit * 0.0;
  padding-right: $spacing-unit * 0.5;
  vertical-align: top;
  //float: left;
  &:visited {
    color: $footer-font-color;
  }
  &:hover {
    color: $header-nav-active-border-color;
  }
  @include media-query($on-palm) {
    margin-bottom: $spacing-unit * 0.3;
  }
}

.contact-list-link {
  
  color: $footer-font-color;
  .contact-fa {
    padding-right: $spacing-unit * 0.3;
    //color: $header-background-color;
  }
  
  &:visited {
    color: $footer-font-color;
    .contact-fa {
      //color:  $header-background-color;
    }
  }
  
  &:hover {
    color: $header-nav-active-border-color;
    .contact-fa {
      color: $header-nav-active-border-color;
    }
  }
  
}


@media (min-width: $on-laptop) {
  .short {
    display: none; /* hide the short content on large screens */
  }
  .long {
    display: inline; /* show the long content on large screens */
  }
}

@media (max-width: $on-palm) {
  .long {
    display: none;
  }
  
}




//#contact-github {
//  color: #4D85BD;
//}
//
//#contact-scholar {
//  color: #486824;
//}
//
//#contact-twitter {
//  color: #D13525;
//}
//
//#contact-linkedin {
//  color: #F2C057;
//}

//.contact-fa {
//  //color: $footer-font-color;
//  padding-left: $spacing-unit * 0.0;
//  padding-right: $spacing-unit * 0.0;
//  text-decoration: none;
//  &:visited {
//    color: $header-background-color;
//  }
//  &:hover {
//    color: $header-nav-active-border-color;
//  }
//}

// Lists
ul.listing {
  list-style-type: none;
  margin-left: 0px;
}

ul.listing li.listing-seperator {
  padding-top: 15px;
  font-weight: bold;
  font-size: 1.17em;
}

ul.listing li.listing-item time {
  color: #333;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 10px;
}

ul.listing li.listing-item a {
  font-weight: 400;
  font-family: $sans-serif;
}


// --------------------------------------------------------
// Header and Nav Bar
// --------------------------------------------------------

.site-header {
  @extend .unselectable;
  @extend .reset-spacing;
  -webkit-box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  background: $header-background-color;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  //justify-content:space-between;
}

.site-header-container {
  display: flex;
  justify-content:space-between;
  @include media-query($on-palm) {
    padding-top: 0.5 * $spacing-unit;
    padding-bottom: 0.5 * $spacing-unit;
  }
}

.title-container {
  @extend .reset-spacing;
}

.nav-container {
  align-self: flex-end;
}

.site-title {
  font-family: $header-title-font-family;
  font-size: $header-title-font-size;
  font-variant: $header-title-font-variant;
  letter-spacing: $header-title-letter-spacing;
  text-transform: $header-title-text-transform;
  font-weight: $header-title-font-weight;
  color: $header-title-text-color;

  &:visited {
    color: $header-text-color;
    text-decoration: none;
  }

  &:hover {
    color: $header-text-color-hover;
    text-decoration: none;
  }

}

.site-nav {

  @include media-query($on-palm) {
    display: none;
  }

  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }

  li {
    display: inline-block;
    text-align: center;
    font-family: $header-nav-font-family;
    font-weight: $header-nav-font-weight;
    font-size: $header-nav-font-size;
    text-transform: $header-nav-text-transform;
    line-height: $header-nav-line-height;
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;

    &:hover {
      cursor: pointer;
      &:not(.active) {
        //background-color: darken($header-background-color, 5%);
        //border-top: $header-nav-active-border-size solid darken($header-background-color, 5%);
        border-bottom: $header-nav-active-border-size solid $header-nav-active-border-color;
        .page-link {
          color: $header-text-color-hover;
        }
      }
    }
  }

  .active {
    color: $header-text-color-hover;
    border-bottom: $header-nav-active-border-size solid $header-nav-active-border-color;
  }

  .page-link {
    color: $header-text-color;

    &:visited {
      color: $header-text-color;
      text-decoration: none;
    }

    &:hover {
      color: $header-text-color-hover;
      text-decoration: none;
    }
  }

}

.mobile-nav-icon-container {

  display: none;
  padding: 0px;
  @include media-query($on-palm) {
    display: block;
    font-size: $header-title-font-size;
    color: $header-text-color;
  }

  &:hover {
    cursor: pointer;
    color: $header-text-color-hover;
    text-decoration: none;
  }

}

#mobile-nav-menu {
  @extend .reset-spacing;

  display: none;
  background-color: lighten($header-background-color, 5%);
  text-align: right;
  width: 100%;


  ul {
    list-style-type: none;
    margin: 0px;
  }

  li {
    display: list-item;
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;
    padding-left:0.5 * $spacing-unit;
    padding-right: 0.5 * $spacing-unit;

    &:not(:first-child){
      border-top: 2px solid mix($header-border-color, $header-background-color, 10%);
    }

  }

  .page-link {
    color: $header-text-color;
    font-family: $header-nav-font-family;
    font-weight: $header-nav-font-weight;
    text-transform: $header-nav-text-transform;
    font-size: 1.3 * $header-nav-font-size;

    &:visited {
      color: $header-text-color;
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .active {
    background-color: $header-nav-active-border-color;
    color: $header-nav-active-border-color;
  }
}

// --------------------------------------------------------
// Footer
// --------------------------------------------------------

.site-footer {
  @extend .unselectable;
  position: relative;
  bottom: 0;
  border-top: 1px solid $light-gray;
  padding: 0px;
  margin: 0px;
  background-color: $footer-background-color;
}

.footer-list {
  float: right;
  display: inline-block;
  list-style-type: none;
  overflow: hidden;
}

.footer-last-update {
  float: left;
  color: $footer-font-color;
  padding-top: $spacing-unit * 0.5;
  padding-right: $spacing-unit;
  &:before {
    //text-transform: ;
    content: 'Updated on';
  }
}

.footer-list-item {
  display: inline-block;
  text-align: left;
  padding-top: $spacing-unit * 0.5;
  padding-left: $spacing-unit * 2.0;
  vertical-align: top;
}

.footer-list-link {
  color: $footer-font-color;
  &:visited {
    color: $footer-font-color-visited;
  }

  &:hover {
    color: $footer-font-color-hover;
    text-decoration: underline;
  }
}

.footer-fa {
  text-decoration: none;
  &:visited {
    color: $footer-icon-color-hover;
  }
  &:hover {
    color: $footer-icon-color-hover;
  }
}

.footer-wrapper {

  clear: both;
  font-family: $sans-serif;
  font-size: $type-size-footnote;
  width: 100%;

  @include media-query($on-palm) {;

    font-size: 0.9 * $type-size-5;
    padding: $spacing-unit;
    display: block;

    .footer-last-update {
      width: 100%;
      color: $footer-font-color;
      padding: 0px;
      margin: 0px;
      margin-top: $spacing-unit;
    }

    .footer-list {
      float: left;
      margin: 0px;

    }

    .footer-list-item {
      display: block;
      text-align: left;
      padding-left: 0px;
      padding-top: 0.4 * $spacing-unit;
      padding-bottom: 0.4 * $spacing-unit;
    }
  }
}






// --------------------------------------------------------
// Page Content
// --------------------------------------------------------

.page-content {
  padding:0px;
  > p {
    font-size: $type-size-normal;
    margin-bottom: 0.75em;
  }
  margin-top: 50px;
}

html {
  overflow-y: scroll;
}

// --------------------------------------------------------
// Blog Posts
// --------------------------------------------------------

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $type-size-small;
  color: $gray;
}

.post-link {
  display: block;
  font-size: 24px;
}



.research-interests {
  list-style-type: none;
  overflow: hidden;
  //display: inline-block;
  display: block;
  padding: 0px;
  margin:0px;
  
  p {
    font-weight: 600;
    display: inline;
  }


  p:after{
    content: ":";
  }
  
  li {
    padding-top: 0.25 * $spacing-unit;
    word-spacing: 0.1 * $spacing-unit;
  }
}

.news-list {
  
  border: 0px;

  font-size: $type-size-small;
  border-spacing: 0em 0.5em;

  td {
    vertical-align: top;
  }
  
  p {
    margin: 0px;
  }

  .news-item-date {
    text-align: -webkit-left;
    color: lighten($gray, 20%);
    width: 4.5em;
    //font-weight: 200;
  }
  
  .news-item-text {
    text-align: -webkit-left;
    padding-left: 1em;
  }

  
}


// --------------------------------------------------------
// Group 
// --------------------------------------------------------
.member-wrapper {
  @extend .reset-spacing;
  display: flex;
  align-items: center;  /* Vertically centers the content and the image */
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
}

.member-info-container {
  flex: 1;  /* Takes up the remaining space in the container */
  display: block;
  justify-content: left;  /* Horizontally centers the content within the div */
  align-items: center;  /* Vertically centers the content within the div */
  line-height: 150%;

}

.member-info-container span {
  display: block;
}

.member-image-container {
  width: 80px; /* Set the desired square size */
  height: 80px;
  overflow: hidden; /* To crop the image */
  margin-right: $spacing-unit; /* If you want a gap between the content and the image */
}

.member-image-container img {
  width: 100%;
  //border: 5px solid $publication-color;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2.5px 4px 0 rgba(0, 0, 0, 0.19);
  height: auto;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


// --------------------------------------------------------
// Publications
// --------------------------------------------------------

.publication-wrapper {
  @extend .reset-spacing;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2.5px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: $publication-color;
  margin-top: 1.5 * $spacing-unit;
  margin-bottom: 1.2 * $spacing-unit;
  padding: 0.8 * $spacing-unit;
  border-left: 0.5 * $spacing-unit solid $header-background-color;
  //border-left: 0.5 * $spacing-unit solid #EE4D49;
}

.publication-expandable:hover {
  //cursor: pointer;
  background-color: lighten($publication-color, 0.5%);
  .action-icon, .action-text  {
    color: $link-color-hover;
  }

}

.publication-image-container {
  width: 150px; /* Set the desired square size */
  height: 150px;
  overflow: hidden; /* To crop the image */
  /* If you want a gap between the content and the image */
  margin-left: 0.5*$spacing-unit;
  margin-right: 1.0*$spacing-unit;
}

.publication-image-container img {
  width: 50%;
  height: auto;
  display: block;
  
  /* This will ensure the image is centered if it's not a square */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


.publication-container {
  @extend .reset-spacing;
  flex: 1;
}

.publication-title-container {
  font-size: $project-title-font-size;
  line-height: $base-line-height;
  font-weight: 500;
  width: 100%;
  
  a {
    color: $header-background-color;
    &:hover {
      color: $link-color-hover;
    }
    &:visited {
      color: $header-background-color;
    }
  }
  
}

.publication-info-container {
  @extend .reset-spacing;
  font-size: $project-details-font-size;
  line-height: 0.9 * $base-line-height;
}

.publication-authors b {
  font-weight: 400;
}

.publication-label {
  display: inline;
  font-weight: 400;
}

.publication-label:after {
  font-weight: 400;
  content: " \2014 ";
}

.publication-journal {
  display: block;
}



.publication-abstract:before {
  @extend .helper-text;
  content: 'abstract: ';
}


.publication-note {
  //margin-top: 0.5 * $spacing-unit;
  font-size: 1.0 * $project-details-font-size;
  font-weight: 500;
  color: #EE4D49; //darken(#74d2f7, 35%);
  
  > a {
    color: #EE4D49; //darken(#74d2f7, 35%);
    &:hover {
      color: darken(#EE4D49, 35%);
    }
  }
  
}


.publication-details-container {
  display: block;
  padding-top: 0.1 * $spacing-unit;

}

.publication-details-list {

  float: left;
  list-style-type: none;
  overflow: hidden;
  //display: inline-block;
  display: block;
  padding: 0px;
  margin:0px;
  //margin-bottom: 0.5 * $spacing-unit;
  
  
  li {
    
    display: inline-block;
    text-align: center;
    font-size: 8.5pt; //$project-details-font-size;
    //background: darken($publication-color, 5%);
    //border : 0.5px solid darken($publication-color, 25%);
    //box-shadow: 0px 0px 2.5px darken($publication-color, 25%);
    background: lighten($header-background-color, 60%);
    //border : 0.5px solid darken($publication-color, 25%);
    //box-shadow: 5px 10px 2px darken($header-background-color, 25%);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2.5px 4px 0 rgba(0, 0, 0, 0.19);
    z-index: 1000;
    padding-top: 0.2em;
    //padding-bottom: 0.2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    
    
    margin-top: 0.5 * $spacing-unit;
    margin-right: 0.5 * $spacing-unit;
    
    &:hover{
      color: $link-color-hover;
      background: darken($publication-color, 25%);
    }
    
    a {
      color: $heading-text-color;
      text-decoration: none;
    }
    
  }
}

  //@extend .helper-text;

.publication-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


.publication-action {
  display: flex;
  flex-direction: row;
}

.publication-expand-icon {
  @extend .reset-spacing;
  align-self: flex-end;
  font-size: $project-details-font-size;
  color: darken($header-text-color, 20%);
}

//.action-icon {
//  font-family: FontAwesome;
//  font-style: normal;
//  font-weight: 100;
//  font-size: 1.0 * $project-details-font-size;
//}
//
//.action-icon-close:before {
//  @extend .action-icon;
//  //content: "\f077"; //single chevron
//  content: "\f102";
//}
//
//.action-icon-open:before {
//  @extend .action-icon;
//  //content: "\f078";
//  content: "\f103";
//}
//
//.action-text {
//  @extend .helper-text;
//  font-size: 0.9 * $project-details-font-size;
//  text-transform: lowercase;
//}
//
//.action-text-open:before {
//  @extend .action-text;
//  content: "more ";
//}
//
//.action-text-close:before {
//  @extend .action-text;
//  content:"less ";
//}


