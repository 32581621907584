@charset "utf-8";

/* Typography Constants
========================================================================== */
//@import url('https://fonts.googleapis.com/css?family=PT+Serif|Open+Sans:600,400');
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400');
@import url('https://fonts.googleapis.com/css?family=Libre+Franklin:100,200,300,400,600,700,800,900');

/* text-related varaiables */
$doc-font-size              : 14;
$base-line-height			: 1.4;
$spacing-unit 				: 0.8em;
$paragraph-indent           : false;
$indent-var                 : 1.3em;

/* system typefaces */
$serif                      : 'PT Serif', serif;   //Georgia, Times, serif;
$sans-serif                 : -apple-system, BlinkMacSystemFont, "Segoe UI",Roboto, "Helvetica Neue", Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$monospace                  : Monaco, Consolas, "Lucida Console", monospace;
$header-serif               : 'Libre Franklin', sans-serif;

/* generic typefaces */
//$helvetica                  : Helvetica, "Helvetica Neue", Arial, sans-serif;
//$georgia                    : Georgia, serif;
//$times                      : Times, serif;
//$bodoni                     : "Bodoni MT", serif;
//$calisto                    : "Calisto MT", serif;
//$garamond                   : Garamond, serif;

/* type scale */
$type-size-1                : 2.441em;  // ~39.056px
$type-size-2                : 1.953em;  // ~31.248px
$type-size-3                : 1.563em;  // ~25.008px
$type-size-4                : 1.25em;   // ~20px
$type-size-5                : 1em;      // ~16px
$type-size-6                : 0.90em;   // ~12px
$type-size-7                : 0.75em; // ~11px

/* Colors Colors
==========================================================================
generator: https://coolors.co/
from: https://designschool.canva.com/blog/website-color-schemes/
*/

/* basic */
$gray                       : #777;
$dark-gray                  : mix(#000, $gray, 40%);
$darker-gray                : mix(#000, $gray, 60%);
$light-gray                 : mix(#fff, $gray, 50%);
$lighter-gray               : mix(#fff, $gray, 90%);
$orange                     : #e37222;
//$primary-color              : #7a8288;
//$success-color              : #62c462;
//$warning-color              : #f89406;
//$danger-color               : #ee5f5b;
//$info-color                 : #52adc8;


/* Website Variables
========================================================================== */

/* global */
$base-font-family			: $sans-serif;
$base-font-weight			: 300;
$base-font-size 			: $type-size-5;
$base-text-color            : $gray; // darken($gray, 20%); //#4C5365; //$color-blackboard;

$type-size-normal           : $type-size-5;
$type-size-large            : 1.25 * $type-size-normal;
$type-size-small 			: 0.90 * $base-font-size;
$type-size-footnote 		: 0.75 * $base-font-size;
$type-size-tiny 		    : 0.65 * $base-font-size;

$background-color           : #fff;
$border-color               : $lighter-gray;
$helper-text-color          : $light-gray;


/* headings */
$heading-font-family        : $header-serif;
$heading-font-weight        : 300;
$heading-text-color 		: #4C5365;//, 5%); //#056571;
$heading-line-height 		: $base-line-height;

/* links */
$link-color 				: #43C0F6; //$dark-gray; 
$link-color-hover           : mix(#000, $link-color, 25%);
$link-color-visited         : $link-color;

/* rules */
$rule-margin 				: 0.5 * $spacing-unit;

/* header */
$header-background-color	: saturate(#4C5365, 5%); 
$header-text-color 			: $lighter-gray;
$header-font-family 		: $sans-serif;
$header-text-color-visited	: mix(#000, $link-color, 25%);
$header-text-color-hover	: mix(#fff, $link-color, 25%);
$header-border-color 		: mix(#fff, $header-background-color, 25%); 

/* header nav */
$header-nav-font-family 	: $header-serif;
$header-nav-font-weight 	: 300;
$header-nav-font-size 		: $base-font-size;
$header-nav-text-transform 	: capitalize;
$header-nav-line-height 	: 1.5 * $base-line-height;

/* active page in nav gets underline */
$header-nav-active-border-size: 3px;
$header-nav-active-border-color: mix(#fff, $link-color, 25%);

/* header title */
$header-title-font-family   : $header-serif;
$header-title-text-color 	: $header-text-color;
$header-title-font-size 	: 1.75 * $base-font-size;
$header-title-text-transform: capitalize;
$header-title-font-variant 	: none;
$header-title-font-weight 	: 400;
$header-title-letter-spacing: 1px;

/* footer */
$footer-background-color	: $background-color; 
$footer-font-color 			: mix(#fff, $gray, 25%); 
$footer-font-color-visited  : $footer-font-color;
$footer-font-color-hover	: mix(#000, $footer-font-color, 50%);
$footer-icon-color 			: $footer-font-color;
$footer-icon-color-hover	: mix(#000, $footer-icon-color, 50%);

/* image captions */
$caption-font-family        : $sans-serif;

/* code */
//$code-background-color      : #fafafa;
//$code-background-color-dark : $light-gray;

/* publications */
$project-title-font-size    : $type-size-normal;
$project-info-font-size 	: $type-size-small;
$project-details-font-size 	: $type-size-footnote;
$publication-color          : lighten(#F9FBF0, 2%);

// width of the content area
$content-width:    700px;
$on-palm:          699px;
$on-laptop:        700px;

// Using media queries with like this:
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

/* Import Other CSS Files 
========================================================================== */
@import "base"; //website elements
//@import "syntax-highlighting"; //highlighting (legacy css)


